import type { KimRatingDialogProps } from './KimRating.types';
import { useIFrameUrl } from './useIFrameUrl';

/**
 * Renders the dialog content for the KimRating component.
 *
 * @param props - The component props.
 * @returns The rendered dialog content.
 */
export const KimRatingDialogContent = (dialogProps: KimRatingDialogProps) => (
  <iframe
    title="kim"
    width="100%"
    height="600"
    style={{ border: 0, overflow: 'hidden' }}
    src={useIFrameUrl(dialogProps)}
  />
);
