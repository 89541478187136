import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  helpUs: {
    id: 'KimRating.HelpUs',
    defaultMessage: 'Hilf uns, besser zu werden!',
  },
  questionStorefront: {
    id: 'KimRating.QuestionStorefront',
    defaultMessage: 'Wie gefällt dir die Startseite?',
  },
  questionDetailpage: {
    id: 'KimRating.QuestionDetailpage',
    defaultMessage: 'Wie gefällt dir die Detailseite?',
  },
  questionSearch: {
    id: 'KimRating.QuestionSearch',
    defaultMessage: 'Wie zufrieden bist du mit unserer Produktliste/Suche?',
  },
  ratingVeryUnsatisfied: {
    id: 'KimRating.RatingVeryUnsatisfied',
    defaultMessage: 'Sehr unzufrieden',
  },
  ratingUnsatisfied: {
    id: 'KimRating.RatingUnsatisfied',
    defaultMessage: 'Unzufrieden',
  },
  ratingNor: {
    id: 'KimRating.RatingNor',
    defaultMessage: 'Weder noch',
  },
  ratingSatisfied: {
    id: 'KimRating.RatingSatisfied',
    defaultMessage: 'Zufrieden',
  },
  ratingVerySatisfied: {
    id: 'KimRating.RatingVerySatisfied',
    defaultMessage: 'Sehr zufrieden',
  },
  ratingOnwards: {
    id: 'KimRating.RatingOnwards',
    defaultMessage: 'Weiter',
  },
});
