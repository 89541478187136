import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgRadio24 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" sx={{
    ...sx
  }} {...other}><circle cx={12} cy={12} r={8.5} fill="#FFF" fillRule="evenodd" /></SvgIcon>;
};
const Memo = memo(SvgRadio24);
export default Memo;