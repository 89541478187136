import type { ChipTypeMap, ChipProps as MuiChipProps } from '@mui/material';
import type { OverridableComponent } from '@mui/material/OverridableComponent';
import { Chip as MuiChip } from '@mui/material';

export type ChipProps = MuiChipProps;

/** Chips are compact elements that represent an input, attribute, or action. https://mui.com/material-ui/react-chip/ */
export const Chip: OverridableComponent<ChipTypeMap> = (props: ChipProps) => (
  <MuiChip {...props} data-testid="mui-chip" />
);
