import { Fade as MuiFade } from '@mui/material';
import type { FadeProps as MuiFadeProps } from '@mui/material';
import { forwardRef } from 'react';

/** Fade Transition. Based on MUI Fade: https://mui.com/material-ui/transitions/#fade */
export const Fade = forwardRef<HTMLDivElement, MuiFadeProps>((props, ref) => {
  const { children } = props;

  return (
    <MuiFade data-testid="fade" {...props} ref={ref}>
      {children}
    </MuiFade>
  );
});
